import { Environment, PanelId, UI } from "./types";

export const uiInitialState: UI = {
  activeCameraViewId: null,
  activeImageId: null,
  activeModelId: null,
  activePanelId: PanelId.MODEL,
  activeScanId: null,
  disableDownloads: false,
  environment: Environment.UNKNOWN,
  isSmallScreen: true,
  mediaSDDirectoryIsEmptyOrDoesNotExist: false,
  requestsToRetry: [],
};
