/**
 * Enum for app environment.
 */
export enum Environment {
  CLOUD = "CLOUD",
  VEHICLE = "VEHICLE",
  UNKNOWN = "UNKNOWN",
}

/**
 * Enum for smallScreen tabbed panels.
 */
export enum PanelId {
  IMAGE = "IMAGE",
  MODEL = "MODEL",
  THUMBNAIL = "THUMBNAIL",
}

/**
 * A list of requests to retry.
 */
export type RequestsToRetry = { func: (arg: any) => any; arg: object }[];

/**
 * Root UI state.
 * Kind of a catchall for simple UI choices, usually from user input, like:
 *  - is this component showing or hidden?
 *  - which one of these things are we showing, if any?
 */
export interface UI {
  activeCameraViewId: string | null;
  activeImageId: string | null;
  activeModelId: string | null;
  activePanelId: PanelId;
  activeScanId: string | null;
  disableDownloads: boolean;
  environment: Environment;
  isSmallScreen: boolean;
  mediaSDDirectoryIsEmptyOrDoesNotExist: boolean;
  requestsToRetry: RequestsToRetry;
}
