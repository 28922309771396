import { PayloadAction } from "@reduxjs/toolkit";

import type { ViewerStateState } from "../types";

import type { ImageViewer, ModelViewer } from "./types";

/**
 * Add id to model viewer loadingObjectIds.
 */
const addLoadingObjectId = (state: ViewerStateState, { payload: id }: PayloadAction<string>) => {
  state.viewers.model.loadingObjectIds = { ...state.viewers.model.loadingObjectIds, [id]: id };
};

/**
 * Remove id from model viewer loadingObjectIds. Has no effect if id isn't in loadingObjectIds.
 */
const removeLoadingObjectId = (state: ViewerStateState, { payload: id }: PayloadAction<string>) => {
  const newLoadingObjectIds = { ...state.viewers.model.loadingObjectIds };
  delete newLoadingObjectIds[id];

  state.viewers.model.loadingObjectIds = newLoadingObjectIds;
};

/**
 * Update all of part of image viewer state.
 */
const updateImageViewer = (
  state: ViewerStateState,
  { payload: updates }: PayloadAction<Partial<ImageViewer>>
) => {
  const imageViewer = state.viewers.image;
  // TODO(rachel): surely there is some way to make typescript happy with a loop?
  if (updates.aspectRatio !== undefined) {
    imageViewer.aspectRatio = updates.aspectRatio;
  }
  if (updates.flyHomeTrigger !== undefined) {
    imageViewer.flyHomeTrigger = updates.flyHomeTrigger;
  }
  if (updates.homeRectangle !== undefined) {
    imageViewer.homeRectangle = updates.homeRectangle;
  }
  if (updates.imageDimensions !== undefined) {
    imageViewer.imageDimensions = updates.imageDimensions;
  }
  if (updates.maxDimension !== undefined) {
    imageViewer.maxDimension = updates.maxDimension;
  }
};

/**
 * Update all of part of model viewer state.
 */
const updateModelViewer = (
  state: ViewerStateState,
  { payload: updates }: PayloadAction<Partial<ModelViewer>>
) => {
  const modelViewer = state.viewers.model;
  // TODO(rachel): surely there is some way to make typescript happy with a loop?
  if (updates.cameraControlPivotPoint !== undefined) {
    modelViewer.cameraControlPivotPoint = updates.cameraControlPivotPoint;
  }
  if (updates.cameraPose !== undefined) {
    modelViewer.cameraPose = updates.cameraPose;
  }
  if (updates.cameraViewPoses !== undefined) {
    modelViewer.cameraViewPoses = updates.cameraViewPoses;
  }
  if (updates.cameraViewShow !== undefined) {
    modelViewer.cameraViewShow = updates.cameraViewShow;
  }
  if (updates.context !== undefined) {
    modelViewer.context = updates.context;
  }
  if (updates.ecefContextCenter !== undefined) {
    modelViewer.ecefContextCenter = updates.ecefContextCenter;
  }
  if (updates.ecefLocation !== undefined) {
    modelViewer.ecefLocation = updates.ecefLocation;
  }
  if (updates.flyHomeTrigger !== undefined) {
    modelViewer.flyHomeTrigger = updates.flyHomeTrigger;
  }
  if (updates.loadingObjectIds !== undefined) {
    modelViewer.loadingObjectIds = updates.loadingObjectIds;
  }
  if (updates.volumeIntentColor !== undefined) {
    modelViewer.volumeIntentColor = updates.volumeIntentColor;
  }
  if (updates.volumeIntentShow !== undefined) {
    modelViewer.volumeIntentShow = updates.volumeIntentShow;
  }
  if (updates.zoomToActiveCameraView !== undefined) {
    modelViewer.zoomToActiveCameraView = updates.zoomToActiveCameraView;
  }
};

export const viewersReducers = {
  addLoadingObjectId,
  removeLoadingObjectId,
  updateImageViewer,
  updateModelViewer,
};
