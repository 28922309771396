import type { CameraViewFilter, Tools } from "./types";

export const cameraViewFilterInitialState: CameraViewFilter = {
  filterByPointInclusion: "off",
  filteredCameraViewsPath: [],
  filterPoint: null,
};

export const toolsInitialState: Tools = {
  cameraViewFilter: cameraViewFilterInitialState,
};
