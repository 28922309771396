/**
 * Get the request index for fetchImage.
 */
export const getFetchImageRequestIndex = (imageId: string | null, scanId: string | null) => {
  return `${scanId}__${imageId}`;
};

/**
 * Get the request index for fetchImageThumbnail.
 */
export const getFetchImageThumbnailRequestIndex = (
  imageId: string | null,
  scanId: string | null
) => {
  return `${scanId}__${imageId}`;
};
