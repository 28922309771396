import type { ImageViewer, ModelViewer, Viewers } from "./types";

export const imageViewerInitialState: ImageViewer = {
  aspectRatio: null,
  flyHomeTrigger: false,
  homeRectangle: null,
  imageDimensions: null,
  maxDimension: null,
};

const modelViewerInitialState: ModelViewer = {
  cameraControlPivotPoint: null,
  cameraPose: null,
  cameraViewPoses: null,
  cameraViewShow: true,
  context: null,
  ecefContextCenter: null,
  ecefLocation: null,
  flyHomeTrigger: true,
  loadingObjectIds: {} as ModelViewer["loadingObjectIds"],
  volumeIntentColor: null,
  volumeIntentShow: true,
  zoomToActiveCameraView: false,
};

export const viewersInitialState: Viewers = {
  image: imageViewerInitialState,
  model: modelViewerInitialState,
};
