import type { Images, ImageThumbnails, Models, ScanOutputs, Scans } from "./types";

export const imagesInitialState: Images = {};
export const imageThumbnailsInitialState: ImageThumbnails = {};
export const modelsInitialState: Models = {};
export const scanOutputsInitialState: ScanOutputs = {};

export const scansInitialState: Scans = {
  images: imagesInitialState,
  imageThumbnails: imageThumbnailsInitialState,
  models: modelsInitialState,
  scanOutputs: scanOutputsInitialState,
};
