import {
  combineReducers,
  createSlice,
  SliceCaseReducers,
  ValidateSliceCaseReducers,
} from "@reduxjs/toolkit";

import { createIndexedRequestReducerFromThunk } from "@skydio/redux_util/src";

import { notifyOfFetchError } from "../components/common/notifications";
import {
  fetchImageErrorTitle,
  fetchModelErrorTitle,
  fetchScanOutputErrorTitle,
} from "../components/common/userMessaging";
import { modelFetchLoadingObjectId } from "../utils/constants";
import { isMediaSDDirectoryIsEmptyOrDoesNotExistError } from "../utils/errors";

import { viewerInitialState } from "./initialState";
import { getFetchImageRequestIndex, getFetchImageThumbnailRequestIndex } from "./requests/utils";
import { scansThunks } from "./scans/thunks";
import { toolsReducers } from "./tools/reducers";
import { ViewerStateState } from "./types";
import { uiReducers } from "./ui/reducers";
import { viewersReducers } from "./viewers/reducers";

const { actions, reducer } = createSlice({
  name: "state",
  initialState: viewerInitialState,
  reducers: {
    ...toolsReducers,
    ...uiReducers,
    ...viewersReducers,
  } as ValidateSliceCaseReducers<ViewerStateState, SliceCaseReducers<ViewerStateState>>,
  extraReducers: builder =>
    builder
      .addCase(scansThunks.fetchImage.fulfilled, (state, action) => {
        // If fetch succeeded, media SD directory exists and is nonempty.
        state.ui.mediaSDDirectoryIsEmptyOrDoesNotExist = false;

        const { imageId, scanId } = action.meta.arg;

        if (!state.scans.images[scanId]) {
          state.scans.images[scanId] = {};
        }

        // Ensure that we release any existing object URLs before creating new ones and lose all
        // chance at preventing a memory leak
        if (state.scans.images[scanId]![imageId]) {
          URL.revokeObjectURL(state.scans.images[scanId]![imageId]!);
        }

        state.scans.images[scanId]![imageId] = URL.createObjectURL(action.payload);
      })
      .addCase(scansThunks.fetchImage.rejected, (state, action) => {
        if (isMediaSDDirectoryIsEmptyOrDoesNotExistError(action.error)) {
          state.ui.mediaSDDirectoryIsEmptyOrDoesNotExist = true;
          state.ui.requestsToRetry = [
            ...state.ui.requestsToRetry,
            {
              func: scansThunks.fetchImage,
              arg: action.meta.arg,
            },
          ];
        } else {
          // Set mediaSDDirectoryIsEmptyOrDoesNotExist false for any other error.
          state.ui.mediaSDDirectoryIsEmptyOrDoesNotExist = false;
          const errorTitle = fetchImageErrorTitle;
          notifyOfFetchError(action.error, errorTitle, state.ui.environment);
        }
      })
      .addCase(scansThunks.fetchImageThumbnail.fulfilled, (state, action) => {
        // If fetch succeeded, media SD directory exists and is nonempty.
        state.ui.mediaSDDirectoryIsEmptyOrDoesNotExist = false;

        const { imageId, scanId } = action.meta.arg;

        if (!state.scans.imageThumbnails[scanId]) {
          state.scans.imageThumbnails[scanId] = {};
        }

        // Ensure that we release any existing object URLs before creating new ones and lose all
        // chance at preventing a memory leak
        if (state.scans.imageThumbnails[scanId]![imageId]) {
          URL.revokeObjectURL(state.scans.imageThumbnails[scanId]![imageId]!);
        }

        state.scans.imageThumbnails[scanId]![imageId] = URL.createObjectURL(action.payload);
      })
      .addCase(scansThunks.fetchImageThumbnail.rejected, (state, action) => {
        if (isMediaSDDirectoryIsEmptyOrDoesNotExistError(action.error)) {
          state.ui.mediaSDDirectoryIsEmptyOrDoesNotExist = true;
          state.ui.requestsToRetry = [
            ...state.ui.requestsToRetry,
            {
              func: scansThunks.fetchImageThumbnail,
              arg: action.meta.arg,
            },
          ];
        } else {
          // Set mediaSDDirectoryIsEmptyOrDoesNotExist false for any other error.
          state.ui.mediaSDDirectoryIsEmptyOrDoesNotExist = false;
        }
      })
      .addCase(scansThunks.fetchModel.fulfilled, (state, action) => {
        // If fetch succeeded, media SD directory exists and is nonempty.
        state.ui.mediaSDDirectoryIsEmptyOrDoesNotExist = false;

        const { modelId, scanId } = action.meta.arg;

        if (!state.scans.models[scanId]) {
          state.scans.models[scanId] = {};
        }

        state.scans.models[scanId]![modelId] = action.payload;
      })
      .addCase(scansThunks.fetchModel.rejected, (state, action) => {
        if (isMediaSDDirectoryIsEmptyOrDoesNotExistError(action.error)) {
          state.ui.mediaSDDirectoryIsEmptyOrDoesNotExist = true;
          state.ui.requestsToRetry = [
            ...state.ui.requestsToRetry,
            {
              func: scansThunks.fetchModel,
              arg: action.meta.arg,
            },
          ];
        } else {
          // Set mediaSDDirectoryIsEmptyOrDoesNotExist false for any other error.
          state.ui.mediaSDDirectoryIsEmptyOrDoesNotExist = false;
          const errorTitle = fetchModelErrorTitle;
          notifyOfFetchError(action.error, errorTitle, state.ui.environment);
        }
        // Remove any loading status for the model GLTF file.
        const newLoadingObjectIds = { ...state.viewers.model.loadingObjectIds };
        delete newLoadingObjectIds[modelFetchLoadingObjectId];

        state.viewers.model.loadingObjectIds = newLoadingObjectIds;
      })
      .addCase(scansThunks.fetchScanOutput.fulfilled, (state, action) => {
        // If fetch succeeded, media SD directory exists and is nonempty.
        state.ui.mediaSDDirectoryIsEmptyOrDoesNotExist = false;

        const { scanId } = action.meta.arg;
        state.scans.scanOutputs[scanId] = action.payload;
      })
      .addCase(scansThunks.fetchScanOutput.rejected, (state, action) => {
        if (isMediaSDDirectoryIsEmptyOrDoesNotExistError(action.error)) {
          state.ui.mediaSDDirectoryIsEmptyOrDoesNotExist = true;
          state.ui.requestsToRetry = [
            ...state.ui.requestsToRetry,
            {
              func: scansThunks.fetchScanOutput,
              arg: action.meta.arg,
            },
          ];
        } else {
          // Set mediaSDDirectoryIsEmptyOrDoesNotExist false for any other error.
          state.ui.mediaSDDirectoryIsEmptyOrDoesNotExist = false;
          const errorTitle = fetchScanOutputErrorTitle;
          notifyOfFetchError(action.error, errorTitle, state.ui.environment);
        }
      }),
});

const requestsReducer = combineReducers({
  fetchImage: createIndexedRequestReducerFromThunk(scansThunks.fetchImage, ({ imageId, scanId }) =>
    getFetchImageRequestIndex(imageId, scanId)
  ),
  fetchImageThumbnail: createIndexedRequestReducerFromThunk(
    scansThunks.fetchImageThumbnail,
    ({ imageId, scanId }) => getFetchImageThumbnailRequestIndex(imageId, scanId)
  ),
  fetchModel: createIndexedRequestReducerFromThunk(scansThunks.fetchModel, "modelId"),
  fetchScanOutput: createIndexedRequestReducerFromThunk(scansThunks.fetchScanOutput, "scanId"),
});

export const viewerReducer = combineReducers({
  state: reducer,
  requests: requestsReducer,
});
export type ViewerState = ReturnType<typeof viewerReducer>;
export { actions as viewerActions };
export const viewerThunks = scansThunks;
