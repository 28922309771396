import { createAsyncThunk } from "@reduxjs/toolkit";

import ScanClient from "@skydio/scan_util/src/ScanClient";

import { convertScanOutput } from "./utils";

/**
 * Fetch scan image.
 */
export const fetchImage = createAsyncThunk(
  "scans/fetchImage",
  async ({
    scanClient,
    imageId,
    scanId,
  }: {
    scanClient: ScanClient;
    imageId: string;
    scanId: string;
  }) => {
    return await scanClient.getImageBlob(scanId, imageId);
  }
);

/**
 * Fetch scan image thumbnail.
 */
export const fetchImageThumbnail = createAsyncThunk(
  "scans/fetchImageThumbnail",
  async ({
    scanClient,
    imageId,
    scanId,
  }: {
    scanClient: ScanClient;
    imageId: string;
    scanId: string;
  }) => {
    return await scanClient.getImageThumbnailBlob(scanId, imageId);
  }
);

/**
 * Fetch scan model.
 */
export const fetchModel = createAsyncThunk(
  "scans/fetchModel",
  async ({
    scanClient,
    modelId,
    scanId,
  }: {
    scanClient: ScanClient;
    modelId: string;
    scanId: string;
  }) => {
    return await scanClient.getMesh(scanId, modelId);
  }
);

/**
 * Fetch full scan output for scanId.
 */
export const fetchScanOutput = createAsyncThunk(
  "scans/fetchScanOutput",
  async ({ scanId, scanClient }: { scanClient: ScanClient; scanId: string }) => {
    const scanOutput = await scanClient.getScanOutput(scanId);
    return convertScanOutput(scanOutput);
  }
);

export const scansThunks = {
  fetchImage,
  fetchImageThumbnail,
  fetchModel,
  fetchScanOutput,
};
