import _ from "lodash";
import { PayloadAction } from "@reduxjs/toolkit";

import type { ViewerStateState } from "../types";

import type { CameraViewFilter } from "./types";

/**
 * Set cameraViewFilter filterByPointInclusion and reset its dependencies.
 */
export const setCameraViewFilterFilterByPointInclusion = (
  state: ViewerStateState,
  { payload: filterByPointInclusion }: PayloadAction<CameraViewFilter["filterByPointInclusion"]>
) => {
  state.tools.cameraViewFilter.filterPoint = null;
  state.tools.cameraViewFilter.filterByPointInclusion = filterByPointInclusion;
};

/**
 * Set cameraViewFilter filteredCameraViewsPath and reset its dependencies.
 * NOTE(rachel): activeCameraViewId must be a member of filteredCameraViewsPath, so whenever
 *   filteredCameraViewsPath changes, activeCameraViewId must be reset to null, which will
 *   trigger it to be set appropriately by components.
 */
export const setCameraViewFilterFilteredCameraViewsPath = (
  state: ViewerStateState,
  { payload: filteredCameraViewsPath }: PayloadAction<CameraViewFilter["filteredCameraViewsPath"]>
) => {
  // compare current and new filteredCameraViewsPath to see if activeCameraViewId needs to change.
  const pathHasChanged = !_.isEqual(
    filteredCameraViewsPath,
    state.tools.cameraViewFilter.filteredCameraViewsPath
  );
  if (pathHasChanged) {
    state.ui.activeCameraViewId = null;
  }

  state.tools.cameraViewFilter.filteredCameraViewsPath = filteredCameraViewsPath;
};

/**
 * Set cameraViewFilter filterPoint.
 */
export const setCameraViewFilterFilterPoint = (
  state: ViewerStateState,
  { payload: filterPoint }: PayloadAction<CameraViewFilter["filterPoint"]>
) => {
  state.tools.cameraViewFilter.filterPoint = filterPoint;
};

export const toolsReducers = {
  setCameraViewFilterFilterByPointInclusion,
  setCameraViewFilterFilteredCameraViewsPath,
  setCameraViewFilterFilterPoint,
};
