import { scansInitialState } from "./scans/initialState";
import { toolsInitialState } from "./tools/initialState";
import { uiInitialState } from "./ui/initialState";
import { viewersInitialState } from "./viewers/initialState";

import type { ViewerStateState } from "./types";

export const viewerInitialState: ViewerStateState = {
  scans: scansInitialState,
  tools: toolsInitialState,
  ui: uiInitialState,
  viewers: viewersInitialState,
};
