import type { SerializedError } from "@reduxjs/toolkit";

// NOTE(rachel): We need better error handling. https://skydio.atlassian.net/browse/SW-28382
// This should be part of the APIs. In the meantime, here's an enum of error names that the
// viewer has special handling for.
export enum ErrorType {
  BUSY = "Busy",
  MEDIA_SD_DIRECTORY_IS_EMPTY_OR_DOES_NOT_EXIST = "MediaSDDirectoryIsEmptyOrDoesNotExist",
  NO_PERMISSION_CODE = "403",
  NO_PERMISSION_MESSAGE = "permission",
  NOT_FOUND = "NotFound",
  NOT_FOUND_CLOUD = "Not Found",
  // Cloud file status: promised but not uploaded
  NOT_UPLOADED = "NotUploaded",
  SCAN_DATAS_IS_EMPTY = "ScanDatasIsEmpty",
  SCAN_NOT_FOUND_IN_SCAN_DATAS = "ScanNotFoundInScanDatas",
  SCANS_DIRECTORY_DOES_NOT_EXIST = "ScansDirectoryDoesNotExist",
  TIMEOUT = "Timeout",
  TOO_MANY_REQUESTS = "TooManyRequests",
}

export const isMediaSDDirectoryIsEmptyOrDoesNotExistError = (error: SerializedError) => {
  const errorString = `${error.name} ${error.message}`.toLowerCase();

  return errorString.includes(
    ErrorType.MEDIA_SD_DIRECTORY_IS_EMPTY_OR_DOES_NOT_EXIST.toLowerCase()
  );
};
