import React from "react";

import { Button } from "antd";

import { RedoIcon } from "./icons";

// Fallbacks
export const defaultMissingDataFallback = "Data is Missing";
export const missingScanNameFallback = "No Name";

// Fullscreen
export const fullscreenToggleTooltip = "Toggle Fullscreen";

// Menus
// Downloads
export const downloadImageMenuTitle = "Download Image";
export const downloadModelMenuTitle = "Download Model";
export const downloadFilenameInputLabel = "Save As";
// Help
export const helpTitle = "Help";
export const helpControlsTitle = "Controls";
// Info
export const infoTitle = "Info";
export const infoScanTitle = "Scan";
export const infoModelTitle = "Model";
export const infoCameraViewTitle = "Capture Point";
// Settings
export const settingsMenuTitle = "Settings";
export const modelSettingsTitle = "Model";
export const modelSelectorTitle = "Select Model Type";
// NOTE(rachel): maybe rework ModelType enum to move names here.
export const zoomToActiveCameraViewTitle = "Zoom to Selected Capture Point";
export const zoomToActiveCameraViewHelpMessage =
  "Automatically align the view with the selected Capture Point.";
export const showAllCameraViewsTitle = "Show All Capture Points";
export const showAllCameraViewsHelpMessage = "Show selected and unselected Capture Points.";
export const showVolumeIntentTitle = "Show Volume";
export const showVolumeIntentHelpMessage = "Show the user-defined scan volume.";

// Navigator
export const navigatorUndefinedCameraViewIdTitle = "Invalid Capture Point";
export const navigatorUndefinedCameraViewIdMessage = "Select another Capture Point.";
export const navigatorFirstTooltip = "Skip to Start";
export const navigatorLastTooltip = "Skip to End";
export const navigatorNextTooltip = "Next";
export const navigatorPreviousTooltip = "Previous";

// Panel Tabs
export const imageTabTitle = "Image";
export const modelTabTitle = "Model";
export const thumbnailTabTitle = "Gallery";

// Placeholders
export const defaultUnloadedDataPlaceholderMessage = "Scan data not loaded.";
export const errorPlaceholderMessage = "Sorry, an unknown error occurred.";
export const imagePanelNoCameraViewSelectedMessage = "Select a Capture Point to view image.";
export const imagePanelLoadingMessage = "Loading image.";
export const imagePanelErrorMessage = "Error loading image.";
export const imagePanelNoImagePath = "No image";
export const imagePanelNoImagesErrorTitle = "No Image Found";
export const imagePanelNoImagesErrorMessage = "This Capture Point did not save an image.";
export const imagePanelNoIdErrorMessage = "This image cannot be found because it has no ID.";
export const imagePanelNoPermissionErrorMessage =
  "Check that you have permission to view this image.";
export const imagePanelNotUploadedErrorMessage =
  "Connect your drone to finish uploading this image.";
export const panelsNoneSelectedMessage = "Select a scan.";
export const placeholderNoCapturePointsMessage = "No Capture Points.";
export const thumbnailNoImagePath = "No image";

// Titlebar
export const inspectToolToggleTooltip = "Toggle Inspect Tool";

// Viewers
export const flyHomeButtonTooltip = "Reset View";

// Notifications/Help Messages
export const scanHasNoModelsTitle = "No Models Found";
export const scanHasNoModelsMessage = "This scan contains no models to display.";
export const fetchImageErrorTitle = "Error Loading Image";
export const fetchModelErrorTitle = "Error Loading Model";
export const fetchScanOutputErrorTitle = "Error Loading Scan";
export enum ClientErrorHelpFix {
  CHECK_CONNECTION = "CHECK_CONNECTION",
  CHECK_CLOUD_FILE_EXISTENCE = "CHECK_CLOUD_FILE_EXISTENCE",
  CHECK_MEDIA_SD_CARD_CONTENTS = "CHECK_MEDIA_SD_CARD_CONTENTS",
  CHECK_PERMISSIONS = "CHECK_PERMISSIONS",
  FINISH_UPLOAD = "FINISH_UPLOAD",
  RELOAD_APP = "RELOAD_APP",
  WAIT = "WAIT",
}

/** The order in which to list fixes in message. */
const clientErrorHelpMessageFixesOrder = [
  ClientErrorHelpFix.CHECK_PERMISSIONS,
  ClientErrorHelpFix.FINISH_UPLOAD,
  ClientErrorHelpFix.CHECK_MEDIA_SD_CARD_CONTENTS,
  ClientErrorHelpFix.CHECK_CLOUD_FILE_EXISTENCE,
  ClientErrorHelpFix.WAIT,
  ClientErrorHelpFix.CHECK_CONNECTION,
  ClientErrorHelpFix.RELOAD_APP,
];

const clientErrorHelpMessageFixes: { [key in ClientErrorHelpFix]: string | JSX.Element } = {
  [ClientErrorHelpFix.CHECK_CONNECTION]: "Check your connection and try again.",
  [ClientErrorHelpFix.CHECK_CLOUD_FILE_EXISTENCE]:
    "Check that the data is available in Skydio Cloud.",
  [ClientErrorHelpFix.CHECK_MEDIA_SD_CARD_CONTENTS]:
    "Check that the data exists on your drone's media SD card.",
  [ClientErrorHelpFix.CHECK_PERMISSIONS]: "Check that you have permission to view this data.",
  [ClientErrorHelpFix.FINISH_UPLOAD]: "Connect your drone to finish uploading this file.",
  [ClientErrorHelpFix.RELOAD_APP]: (
    <div>
      <Button className={"bordered"} onClick={() => window?.location?.reload()} type={"text"}>
        <RedoIcon />
        {"Reload the Model Viewer"}
      </Button>
      {" and try again."}
    </div>
  ),
  [ClientErrorHelpFix.WAIT]: "Wait a minute and try again.",
};

/**
 * Get help message for client errors, including list of possible fixes.
 */
export const getClientErrorHelpMessage = (possibleFixes: Set<ClientErrorHelpFix>) => {
  return possibleFixes.size > 0 ? (
    <>
      <p>Try the following steps to resolve the issue:</p>
      <ul>
        {clientErrorHelpMessageFixesOrder.map(fix =>
          possibleFixes.has(fix) ? <li key={fix}>{clientErrorHelpMessageFixes[fix]}</li> : null
        )}
      </ul>
    </>
  ) : (
    <p>There are no known solutions for this issue.</p>
  );
};

// WebGL
export const webGlPlaceholderTitle = "Error Initializing WebGL";
export const webGlSupportFailedMessage = "Your browser does not support WebGL.";
export const webGlInitializationFailedMessage =
  "Your browser supports WebGL, but it failed to initialize.";
export const webGlHelpMessage = (
  <>
    <p>
      To fix this issue, try each step below and check the result of{" "}
      <a href="https://get.webgl.org/">this WebGL test</a> each time.
    </p>
    <ol>
      <li>
        Update your browser to <a href="https://caniuse.com/webgl">a version that supports WebGL</a>
        , or use a different browser.
      </li>
      <li>
        Ensure that WebGL is enabled in your browser. Search the internet for instructions, or visit
        the support site for your browser.
        <ul>
          <li>
            <a href="https://support.apple.com/safari">Safari</a>
          </li>
          <li>
            <a href="https://support.google.com/chrome">Chrome</a>
          </li>
          <li>
            <a href="https://support.microsoft.com/en-us/microsoft-edge">Edge</a>
          </li>
          <li>
            <a href="https://support.mozilla.org/en-US/products/firefox">Firefox</a>
          </li>
        </ul>
      </li>
      <li>
        Ensure that hardware acceleration is enabled in your browser. Open your browser's settings
        menu and search for "hardware acceleration", or search the internet for instructions.
      </li>
      <li>Restart your device in case the graphics driver is not operating correctly.</li>
      <li>
        Update your graphics driver to the latest version, downgrade your browser to a version that
        supports your graphics driver, or use a different browser.
      </li>
    </ol>

    <p>
      For more details on your browser's WebGL support, visit{" "}
      <a href="https://webglreport.com/">WebGL Report</a>.
    </p>
  </>
);
/** For any webgl runtime error, most likely due to hardware acceleration not being enabled. */
export const webGlRuntimeErrorTitle = "Error Running WebGL";
export const webGlRuntimeErrorHelpMessage = (
  <>
    <p>
      This is likely due to an issue with your browser or graphics driver. To fix the issue, try
      each step below and reload the page each time.
    </p>
    <p>
      To verfiy that WebGL is working, you can also visit{" "}
      <a href="https://webglreport.com/">WebGL Report</a> and check that{" "}
      <strong>Major Performance Caveat</strong> says "No".
    </p>
    <ol>
      <li>
        Ensure that hardware acceleration is enabled in your browser. Open your browser's settings
        menu and search for "hardware acceleration", or search the internet for instructions.
      </li>
      <li>Restart your device in case the graphics driver is not operating correctly.</li>
      <li>
        Update your graphics driver to the latest version, downgrade your browser to a version that
        supports your graphics driver, or use a different browser.
      </li>
    </ol>
  </>
);
