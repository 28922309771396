import React, { FunctionComponent } from "react";

import { IconDefinition } from "@fortawesome/fontawesome-svg-core";
import {
  faAlignLeft,
  faArrowLeft,
  faArrowRight,
  faArrowUp,
  faBackward,
  faBan,
  faBars,
  faCheckCircle,
  faChevronLeft,
  faCircleNotch,
  faClock,
  faCloudUploadAlt,
  faCog,
  faCompress,
  faCrosshairs,
  faCube,
  faCubes,
  faDownload,
  faExclamationTriangle,
  faExpand,
  faFastBackward,
  faFastForward,
  faFile,
  faFilter,
  faForward,
  faGamepad,
  faGlobe,
  faGripLines,
  faHashtag,
  faHome,
  faImage,
  faImages,
  faInbox,
  faInfo,
  faLock,
  faPlane,
  faQuestion,
  faRedoAlt,
  faRulerCombined,
  faStepBackward,
  faStepForward,
  faSun,
  faTag,
  faTimes,
  faTimesCircle,
  faToolbox,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon as FAIcon, FontAwesomeIconProps } from "@fortawesome/react-fontawesome";

/**
 * All icons should be defined here for consistent UI and easier maintenance and abstraction.
 */

export interface IconProps extends Omit<FontAwesomeIconProps, "icon"> {
  icon: IconDefinition;
}

export type IconType = ReturnType<typeof Icon>;
/**
 * Icon that combines Ant Design and Font Awesome styling and functionality.
 */
export const Icon: FunctionComponent<IconProps> = ({ className, ...props }) => (
  <FAIcon className={`anticon ${className}`} {...props} />
);

/* Icon Definitions */

export const backIconDef = faArrowLeft;
export const brightnessIconDef = faSun;
export const cancelIconDef = faTimes;
export const closeIconDef = faTimes;
export const cloudUploadDef = faCloudUploadAlt;
export const controlsIconDef = faGamepad;
export const descriptionIconDef = faAlignLeft;
export const dimensionsIconDef = faRulerCombined;
export const disabledIconDef = faBan;
export const dragIconDef = faGripLines;
export const downloadIconDef = faDownload;
export const emptyIconDef = faInbox;
export const errorIconDef = faTimesCircle;
export const fileIconDef = faFile;
export const filterIconDef = faFilter;
export const flightIconDef = faPlane;
export const fullLeftIconDef = faFastBackward;
export const fullRightIconDef = faFastForward;
export const fullScreenEnterIconDef = faExpand;
export const fullScreenExitIconDef = faCompress;
export const forwardIconDef = faArrowRight;
export const helpIconDef = faQuestion;
export const homeIconDef = faHome;
export const idIconDef = faHashtag;
export const infoIconDef = faInfo;
export const inspectIconDef = faCrosshairs;
export const imageIconDef = faImage;
export const menuIconDef = faBars;
export const modelIconDef = faCubes;
export const modelTypeIconDef = faCube;
export const moveLeftIconDef = faChevronLeft;
export const nameIconDef = faTag;
export const noPermissionIconDef = faLock;
export const redoIconDef = faRedoAlt;
export const scanIconDef = faGlobe;
export const settingsIconDef = faCog;
export const skipLeftIconDef = faBackward;
export const skipRightIconDef = faForward;
export const spinnerIconDef = faCircleNotch;
export const stepLeftIconDef = faStepBackward;
export const stepRightIconDef = faStepForward;
export const successIconDef = faCheckCircle;
export const thumbnailsIconDef = faImages;
export const timeIconDef = faClock;
export const toolsIconDef = faToolbox;
export const upIconDef = faArrowUp;
export const warningIconDef = faExclamationTriangle;

/* Icons with defined icon prop. */

export type DefinedIconProps = Omit<IconProps, "icon">;

export const BackIcon: FunctionComponent<DefinedIconProps> = ({ ...props }) => (
  <Icon icon={backIconDef} {...props} />
);
export const BrightnessIcon: FunctionComponent<DefinedIconProps> = ({ ...props }) => (
  <Icon icon={brightnessIconDef} {...props} />
);
export const CancelIcon: FunctionComponent<DefinedIconProps> = ({ ...props }) => (
  <Icon icon={cancelIconDef} {...props} />
);
export const CloseIcon: FunctionComponent<DefinedIconProps> = ({ ...props }) => (
  <Icon icon={closeIconDef} {...props} />
);
export const CloudUploadIcon: FunctionComponent<DefinedIconProps> = ({ ...props }) => (
  <Icon icon={cloudUploadDef} {...props} />
);
export const ControlsIcon: FunctionComponent<DefinedIconProps> = ({ ...props }) => (
  <Icon icon={controlsIconDef} {...props} />
);
export const DescriptionIcon: FunctionComponent<DefinedIconProps> = ({ ...props }) => (
  <Icon icon={descriptionIconDef} {...props} />
);
export const DimensionsIcon: FunctionComponent<DefinedIconProps> = ({ ...props }) => (
  <Icon icon={dimensionsIconDef} {...props} />
);
export const DisabledIcon: FunctionComponent<DefinedIconProps> = ({ ...props }) => (
  <Icon icon={disabledIconDef} {...props} />
);
export const DragIcon: FunctionComponent<DefinedIconProps> = ({ ...props }) => (
  <Icon icon={dragIconDef} {...props} />
);
export const DownloadIcon: FunctionComponent<DefinedIconProps> = ({ ...props }) => (
  <Icon icon={downloadIconDef} {...props} />
);
export const ErrorIcon: FunctionComponent<DefinedIconProps> = ({ ...props }) => (
  <Icon icon={errorIconDef} {...props} />
);
export const FileIcon: FunctionComponent<DefinedIconProps> = ({ ...props }) => (
  <Icon icon={fileIconDef} {...props} />
);
export const FilterIcon: FunctionComponent<DefinedIconProps> = ({ ...props }) => (
  <Icon icon={filterIconDef} {...props} />
);
export const FlightIcon: FunctionComponent<DefinedIconProps> = ({ ...props }) => (
  <Icon icon={flightIconDef} {...props} />
);
export const FullLeftIcon: FunctionComponent<DefinedIconProps> = ({ ...props }) => (
  <Icon icon={fullLeftIconDef} {...props} />
);
export const FullRightIcon: FunctionComponent<DefinedIconProps> = ({ ...props }) => (
  <Icon icon={fullRightIconDef} {...props} />
);
export const FullScreenEnterIcon: FunctionComponent<DefinedIconProps> = ({ ...props }) => (
  <Icon icon={fullScreenEnterIconDef} {...props} />
);
export const FullScreenExitIcon: FunctionComponent<DefinedIconProps> = ({ ...props }) => (
  <Icon icon={fullScreenExitIconDef} {...props} />
);
export const ForwardIcon: FunctionComponent<DefinedIconProps> = ({ ...props }) => (
  <Icon icon={forwardIconDef} {...props} />
);
export const HelpIcon: FunctionComponent<DefinedIconProps> = ({ ...props }) => (
  <Icon icon={helpIconDef} {...props} />
);
export const HomeIcon: FunctionComponent<DefinedIconProps> = ({ ...props }) => (
  <Icon icon={homeIconDef} {...props} />
);
export const IdIcon: FunctionComponent<DefinedIconProps> = ({ ...props }) => (
  <Icon icon={idIconDef} {...props} />
);
export const InfoIcon: FunctionComponent<DefinedIconProps> = ({ ...props }) => (
  <Icon icon={infoIconDef} {...props} />
);
export const InspectIcon: FunctionComponent<DefinedIconProps> = ({ ...props }) => (
  <Icon icon={inspectIconDef} {...props} />
);
export const ImageIcon: FunctionComponent<DefinedIconProps> = ({ ...props }) => (
  <Icon icon={imageIconDef} {...props} />
);
export const MenuIcon: FunctionComponent<DefinedIconProps> = ({ ...props }) => (
  <Icon icon={menuIconDef} {...props} />
);
export const ModelIcon: FunctionComponent<DefinedIconProps> = ({ ...props }) => (
  <Icon icon={modelIconDef} {...props} />
);
export const ModelTypeIcon: FunctionComponent<DefinedIconProps> = ({ ...props }) => (
  <Icon icon={modelTypeIconDef} {...props} />
);
export const MoveLeftIcon: FunctionComponent<DefinedIconProps> = ({ ...props }) => (
  <Icon icon={moveLeftIconDef} {...props} />
);
export const NameIcon: FunctionComponent<DefinedIconProps> = ({ ...props }) => (
  <Icon icon={nameIconDef} {...props} />
);
export const NoPermissionIcon: FunctionComponent<DefinedIconProps> = ({ ...props }) => (
  <Icon icon={noPermissionIconDef} {...props} />
);
export const RedoIcon: FunctionComponent<DefinedIconProps> = ({ ...props }) => (
  <Icon icon={redoIconDef} {...props} />
);
export const ScanIcon: FunctionComponent<DefinedIconProps> = ({ ...props }) => (
  <Icon icon={scanIconDef} {...props} />
);
export const SettingsIcon: FunctionComponent<DefinedIconProps> = ({ ...props }) => (
  <Icon icon={settingsIconDef} {...props} />
);
export const SkipLeftIcon: FunctionComponent<DefinedIconProps> = ({ ...props }) => (
  <Icon icon={skipLeftIconDef} {...props} />
);
export const SkipRightIcon: FunctionComponent<DefinedIconProps> = ({ ...props }) => (
  <Icon icon={skipRightIconDef} {...props} />
);
export const SpinnerIcon: FunctionComponent<Omit<DefinedIconProps, "spin">> = ({ ...props }) => (
  <Icon icon={spinnerIconDef} spin={true} {...props} />
);
export const StepLeftIcon: FunctionComponent<DefinedIconProps> = ({ ...props }) => (
  <Icon icon={stepLeftIconDef} {...props} />
);
export const StepRightIcon: FunctionComponent<DefinedIconProps> = ({ ...props }) => (
  <Icon icon={stepRightIconDef} {...props} />
);
export const SuccessIcon: FunctionComponent<DefinedIconProps> = ({ ...props }) => (
  <Icon icon={successIconDef} {...props} />
);
export const ThumbnailsIcon: FunctionComponent<DefinedIconProps> = ({ ...props }) => (
  <Icon icon={thumbnailsIconDef} {...props} />
);
export const TimeIcon: FunctionComponent<DefinedIconProps> = ({ ...props }) => (
  <Icon icon={timeIconDef} {...props} />
);
export const ToolsIcon: FunctionComponent<DefinedIconProps> = ({ ...props }) => (
  <Icon icon={toolsIconDef} {...props} />
);
export const UpIcon: FunctionComponent<DefinedIconProps> = ({ ...props }) => (
  <Icon icon={upIconDef} {...props} />
);
export const WarningIcon: FunctionComponent<DefinedIconProps> = ({ ...props }) => (
  <Icon icon={warningIconDef} {...props} />
);
