import { PayloadAction } from "@reduxjs/toolkit";

import { ImageMetas } from "../scans/types";
import { ViewerStateState } from "../types";
import { viewersInitialState } from "../viewers/initialState";

import { Environment, PanelId, RequestsToRetry } from "./types";

/**
 * Set activeCameraViewId and reset its dependencies.
 */
export const setActiveCameraViewId = (
  state: ViewerStateState,
  { payload: cameraViewId }: PayloadAction<string | null>
) => {
  const activeScanId = state.ui.activeScanId;
  const activeScanOutput = state.scans.scanOutputs[activeScanId ?? ""];
  const cameraViewMeta = activeScanOutput?.cameraViewMetas[cameraViewId ?? ""];
  const imageMetasArray = Object.values(cameraViewMeta?.imageMetas ?? ({} as ImageMetas));
  const imageMeta = imageMetasArray[0];
  const imageId = imageMeta?.id ?? null;

  state.ui.activeCameraViewId = cameraViewId;
  state.ui.activeImageId = imageId;
};

/**
 * Set activeModelId and reset its dependencies.
 */
export const setActiveModelId = (
  state: ViewerStateState,
  { payload: modelId }: PayloadAction<string | null>
) => {
  state.ui.activeModelId = modelId;
};

/**
 * Set activePanelId.
 */
export const setActivePanelId = (
  state: ViewerStateState,
  { payload: activePanel }: PayloadAction<PanelId>
) => {
  state.ui.activePanelId = activePanel;
};

/**
 * Set activeScanId and reset its dependencies.
 */
export const setActiveScanId = (
  state: ViewerStateState,
  { payload: scanId }: PayloadAction<string | null>
) => {
  // Drop cached assets from other scans
  // TODO(josiah): come up with a better caching strategy
  let images = {};
  let imageThumbnails = {};
  let models = {};
  if (scanId) {
    images = { [scanId]: state.scans.images[scanId] };
    // Release other image objects
    for (const otherScanId in state.scans.images) {
      if (otherScanId !== scanId) {
        const otherImages = state.scans.images[otherScanId];
        for (const imageId in otherImages) {
          URL.revokeObjectURL(otherImages[imageId]!);
        }
      }
    }
    imageThumbnails = { [scanId]: state.scans.imageThumbnails[scanId] };
    // Release other thumbnail objects
    for (const otherScanId in state.scans.imageThumbnails) {
      if (otherScanId !== scanId) {
        const otherThumbnails = state.scans.imageThumbnails[otherScanId];
        for (const thumbId in otherThumbnails) {
          URL.revokeObjectURL(otherThumbnails[thumbId]!);
        }
      }
    }
    models = { [scanId]: state.scans.models[scanId] };
  }

  state.ui.activeCameraViewId = null;
  state.ui.activeImageId = null;
  state.ui.activeModelId = null;
  state.ui.activeScanId = scanId;
  state.scans.images = images;
  state.scans.imageThumbnails = imageThumbnails;
  state.scans.models = models;
  state.tools.cameraViewFilter.filteredCameraViewsPath = [];
  state.tools.cameraViewFilter.filterPoint = null;
  state.viewers = viewersInitialState;
};

/**
 * Set disableDownloads.
 */
export const setDisableDownloads = (
  state: ViewerStateState,
  { payload: disableDownloads }: PayloadAction<boolean>
) => {
  state.ui.disableDownloads = disableDownloads;
};

/**
 * Set environment.
 */
export const setEnvironment = (
  state: ViewerStateState,
  { payload: environment }: PayloadAction<Environment>
) => {
  state.ui.environment = environment;
};

/**
 * Set isSmallScreen.
 */
export const setIsSmallScreen = (
  state: ViewerStateState,
  { payload: isSmallScreen }: PayloadAction<boolean>
) => {
  state.ui.isSmallScreen = isSmallScreen;
};

/**
 * Set mediaSDDirectoryIsEmptyOrDoesNotExist.
 */
export const setMediaSDDirectoryIsEmptyOrDoesNotExist = (
  state: ViewerStateState,
  { payload: mediaSDDirectoryIsEmptyOrDoesNotExist }: PayloadAction<boolean>
) => {
  state.ui.mediaSDDirectoryIsEmptyOrDoesNotExist = mediaSDDirectoryIsEmptyOrDoesNotExist;
};

/**
 * Set requestsToRetry.
 */
export const setRequestsToRetry = (
  state: ViewerStateState,
  { payload: requestsToRetry }: PayloadAction<RequestsToRetry>
) => {
  state.ui.requestsToRetry = requestsToRetry;
};

export const uiReducers = {
  setActiveCameraViewId,
  setActiveModelId,
  setActivePanelId,
  setActiveScanId,
  setDisableDownloads,
  setEnvironment,
  setIsSmallScreen,
  setMediaSDDirectoryIsEmptyOrDoesNotExist,
  setRequestsToRetry,
};
